@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar-icon {
    font-size: 20px;
}
.ion-avatar {
    font-size: 64px;
}
* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.richtexteditor h2 {
    @apply text-xl font-bold;
}
.richtexteditor h1 {
    @apply text-2xl font-bold;
}
